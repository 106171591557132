footer {
    background: #353744;
    color: #fcfbfd;
    height: 5rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: -1;
    text-align: center;

    a {
        color: #fcfbfd;
    }

    .footer-text {
        height: 3rem;
        width: calc(80% - 2rem);
        float: left;
        padding: 1rem;
        overflow-y: scroll;
        position: relative;

        p {
            font-size: 1rem;
            line-height: 1.5rem;
            margin: 0;
            width: calc(100% - 2rem);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .footer-links {
        height: 100%;
        width: 20%;
        float: right;
        white-space: nowrap;
        overflow-x: scroll;
        position: relative;

        ul {
            font-size: 2rem;
            list-style: none;
            margin: 0;
            padding: 0;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        li {
            display: inline-block;
            padding: 0 0.5rem;
        }

        svg {
            transform: translateY(5%);
        }

        a:hover, a:focus {
            font-size: 3rem;
        }
    }
}
