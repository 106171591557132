.dropdown-section-wrapper {
    margin: 1rem 0;
}

.dropdown-section-button {
    font: inherit;
    text-align: left;
    width: calc(100% + 2rem);
    transform: translateX(-1rem);
    color: #353744;
    background: #fcfbfd;
    border-radius: 1rem;
    border: #353744 0.25rem solid;
    padding: 0.25rem;
    position: relative;
    z-index: 2;

    transition: all 0.25s;
    animation: FadeIn 0.2s linear 0.7s;
    animation-fill-mode: backwards;

    svg {
        margin: 0 0.5rem 0 0.25rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        float: left;
        font-size: 3rem;

        transition: all 0.25s;
        transition: transform 0.5s;
    }

    &:hover, &:focus {
        cursor: pointer;
        padding: 1rem 0.5rem;
        color: #fcfbfd;
        background-color: #0e0e11;
        border-color: #0e0e11;

        h3 {
            font-size: 2.5rem;
        }
    }

    &[aria-expanded="true"]{
        color: #fcfbfd;
        background-color: #353744;
        border-color: #353744;

        svg {
            transform: rotate3d(1, 0, 0, 180deg) translateY(50%);
        }

        &:hover, &:focus {
            cursor: pointer;
            padding: 1rem 0.5rem;
            color: #fcfbfd;
            background-color: #0e0e11;
            border-color: #0e0e11;

            h3 {
                font-size: 2.5rem;
            }
        }
    }
}

.dropdown-section-header-text {
    margin-left: 4rem;

    h3 {
        margin: 0;

        transition: all 0.25s;
    }

    span {
        font-size: 1.5rem;
    }
}

.dropdown-section-content {
    border-radius: 0 0 1rem 1rem;
    border-left: #353744 0.25rem solid;
    border-bottom: #353744 0.25rem solid;
    border-right: #353744 0.25rem solid;
    margin: -1rem -1rem 0 -1rem;
    padding: 1rem 1rem 0 1rem;
    z-index: 1;

    p, ul {
        animation: none;
    }
}