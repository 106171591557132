.top-bar {
    background: #353744;
    color: #fcfbfd;
    height: 12rem;
    position: relative;
}

.header-container {
    margin-left: 12rem;
    height: 100%;
    position: relative;
    overflow-y: hidden;
    overflow-x: scroll;
}