.nav-bar {
    float: left;
    width: 12rem;
    position: fixed;
    top: 0;
    z-index: 3;

    .logo {
        background: #353744;
        border-bottom-right-radius: 0.5rem;
        display: block;
        padding: 5% 0;

        img {
            display: block;
            margin: auto;
            width: 90%;
            height: auto;
        }

        &:hover, &:focus {
            border-radius: 0 0 0.5rem 0;
        }
    }
}

nav {
    background: #353744;
    display: block;
    text-align: center;
    height: auto;
    width: 40%;
    padding: 0 0.5rem 0.25rem;
    border-bottom-right-radius: 0.5rem;

    a {
        font-size: 2rem;
        display: block;
        padding: 0.5rem 0;
        margin-bottom: 0.5rem;
        position: relative;
        text-decoration: none;
        border-radius: 0.5rem;

        svg {
            color: #fcfbfd;
            opacity: 0.5;
        }

        &:hover, &:focus {
            font-size: 3rem;
            padding: 0.5rem;

            svg {
                opacity: 1;
            }
        }
    }

    .active {
        background-color: #fcfbfd;
        font-size: 3rem;
        
        svg {
            color: #0e0e11;
            opacity: 1;
        }
    }
}