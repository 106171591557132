body {
  margin: 0;
  font-family: "Atkinson Hyperlegible", Arial, Helvetica, sans-serif;
  color: #353744;
  background: #fcfbfd;
  display: block;
}

.main-content {
  padding-left: 14rem;
  padding-right: 10rem;
  margin-bottom: 10rem;
  background-color: #fcfbfd;
}

.montserrat {
  font-family: "Montserrat", "Arial Black", Impact, Haettenschweiler, Arial, Helvetica, sans-serif;
  font-weight: 900;
  font-style: italic;
}

h1 {
  margin: 0;
  padding: 0 2rem;
  font-size: 10rem;
  position: absolute;
  white-space: nowrap;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes H2SlideIn {
  0% {margin-left: 100%; font-size: 0;}
  45% {margin-left: -1rem; font-size: 0;}
  55% {font-size: 0;}
  100% {font-size: auto;}
}

h2 {
  font-size: 3rem;
  margin: 1rem -10rem 1rem -1rem;
  padding: 0.9rem 10rem 1.1rem 2rem;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;

  background-color: #353744;
  color: #fcfbfd;

  animation: H2SlideIn 0.7s ease-out;
}

h2 a {
  color: #fcfbfd;
}

@keyframes FadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

h3 {
  font-size: 2rem;
  margin: 1rem 0;

  animation: FadeIn 0.2s linear 0.7s;
  animation-fill-mode: backwards;
}

p, ul {
  font-size: 1.5rem;
  line-height: 2.25rem;
  
  animation: FadeIn 0.2s linear 0.7s;
  animation-fill-mode: backwards;
}

a {
  color: #353744;
  transition: all 0.25s;
}

a:hover, a:focus {
  color: #fcfbfd;
  background-color: #0e0e11;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 150%;
}

.image-paragraph-container {
  height: fit-content;
}

.big-image {
  width: 24rem;
  height: 24rem;
  border-radius: 2rem;
  margin: 0 3rem 2rem 0;
  float: left;
  transform: translate(0.5rem, 0.5rem);

  transition: all 0.5s;

  animation: FadeIn 0.2s linear 0.7s;
  animation-fill-mode: backwards;
}

.big-image:hover, .big-image:focus {
  width: 27rem;
  height: 27rem;
  margin: 0 0 -1rem 0;
  transform: translate(-1rem, -1rem);
  rotate: -1.5deg;
}

.fit-image {
  width: inherit;
  border-radius: inherit;
  margin-top: 50%;
  transform: translateY(-50%);
}

.static-image {
  width: 27rem;
  border-radius: 2rem;
  margin: 0 3rem 1.5rem 0;
  float: left;

  transition: all 0.5s;

  animation: FadeIn 0.2s linear 0.7s;
  animation-fill-mode: backwards;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
